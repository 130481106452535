import axios from '../http_interceptor';

// 获取网站基本配置信息
const getWebsiteConfig = () => axios.post('/api/websiteMessage/show');

// 保存网站基本配置信息
const saveWebsiteConfig = params => axios.put('/api/websiteMessage', params);

// 获取公告列表
const getNoticeList = params => axios.post('/api/notice/page', params);

// 获取版本列表
const getVersionList = params => axios.post('/api/app-version/page', params);

// 新增app版本
const addAppVersion = params => axios.post('/api/app-version', params);

// 编辑app版本
const updateAppVersion = params => axios.put('/api/app-version', params);

// 新增app公告
const addAppNotice = params => axios.post('/api/notice', params);

// 编辑app公告
const updateAppNotice = params => axios.put('/api/notice', params);

// 删除公告
const delAppNoticeById = params => axios.delete(`/api/notice/${params}`);

// 获取日志管理 list
const getLogManageList = params => axios.post('/api/operateLog/page', params);

export default {
  getWebsiteConfig,
  saveWebsiteConfig,
  getNoticeList,
  getVersionList,
  addAppVersion,
  updateAppVersion,
  addAppNotice,
  updateAppNotice,
  delAppNoticeById,
  getLogManageList
};
