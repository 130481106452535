export default {
  path: '/admin', // 后台管理
  name: 'Admin',
  component: () => import('@/views/admin'),
  meta: { requiresAuth: true },
  children: [
    { path: '', redirect: '/admin/home' },
    // {
    //   path: 'home',
    //   name: 'AdminHome',
    //   component: () => import('@/views/admin/home')
    // },
    {
      path: 'user-manage',
      name: 'UserManage',
      component: () => import('@/views/admin/user-manage'),
      redirect: '/admin/user-manage/user-list',
      children: [
        {
          path: 'user-list',
          name: 'UserList',
          component: () => import('@/views/admin/user-manage/user-list')
        },
        {
          path: 'role-manage',
          name: 'RoleManage',
          component: () => import('@/views/admin/user-manage/role-manage')
        },
        {
          path: 'resource-manage',
          name: 'ResourceManage',
          component: () => import('@/views/admin/user-manage/resource-manage')
        }
      ]
    },
    {
      path: 'live-manage',
      name: 'LiveManage',
      component: () => import('@/views/admin/live-manage'),
      redirect: '/admin/live-manage/live-room-list',
      children: [
        {
          path: 'live-room-list',
          name: 'LiveRoomList',
          component: () => import('@/views/admin/live-manage/live-room-list')
        },
        {
          path: 'live-setting',
          name: 'LiveSetting',
          component: () => import('@/views/admin/live-manage/live-setting')
        }
      ]
    },
    {
      path: 'transcoding-manage',
      name: 'TranscodingManage',
      component: () => import('@/views/admin/transcoding-manage'),
      redirect: '/admin/transcoding-manage/video-list',
      children: [
        {
          path: 'video-list',
          name: 'VideoList',
          component: () => import('@/views/admin/transcoding-manage/video-list')
        },
        {
          path: 'video-setting',
          name: 'VideoSetting',
          component: () => import('@/views/admin/transcoding-manage/video-setting')
        }
      ]
    },
    {
      path: 'warning-manage',
      name: 'WarningManage',
      component: () => import('@/views/admin/warning-manage'),
      redirect: '/admin/warning-manage/warning-list',
      children: [
        {
          path: 'warning-list',
          name: 'WarningList',
          component: () => import('@/views/admin/warning-manage/warning-list')
        }
      ]
    },
    {
      path: 'machine-manage',
      name: 'MachineManage',
      component: () => import('@/views/admin/machine-manage'),
      redirect: '/admin/machine-manage/monitor-manage',
      children: [
        {
          path: 'monitor-manage',
          name: 'MonitorManage',
          component: () => import('@/views/admin/machine-manage/monitor-manage')
        },
        {
          path: 'uav-task-list',
          name: 'UavTaskList',
          component: () => import('@/views/admin/machine-manage/uav-task-list')
        },
        {
          path: 'uav-manage',
          name: 'UavManage',
          component: () => import('@/views/admin/machine-manage/uav-manage')
        }
      ]
    },
    {
      path: 'business-manage',
      name: 'BusinessManage',
      component: () => import('@/views/admin/machine-manage'),
      redirect: '/admin/business-manage/ppt-manage',
      children: [
        {
          path: 'ppt-manage',
          name: 'PPTManage',
          component: () => import('@/views/admin/business-manage/ppt-manage')
        },
        {
          path: 'guide-page-manage',
          name: 'GuidePageManage',
          component: () => import('@/views/admin/business-manage/guide-page-manage')
        },
        {
          path: 'tag-manage',
          name: 'TagManage',
          component: () => import('@/views/admin/business-manage/tag-manage')
        },
        {
          path: 'notice-manage',
          name: 'NoticeManage',
          component: () => import('@/views/admin/business-manage/notice-manage')
        }
      ]
    },
    {
      path: 'system-manage',
      name: 'SystemManage',
      component: () => import('@/views/admin/system-manage'),
      children: [
        { path: '', redirect: '/admin/system-manage/dictionary-manage' },
        {
          path: 'dictionary-manage',
          name: 'DictionaryManage',
          component: () => import('@/views/admin/system-manage/dictionary-manage')
        },
        {
          path: 'params-config',
          name: 'ParamsConfig',
          component: () => import('@/views/admin/system-manage/params-config')
        },
        {
          path: 'log-manage',
          name: 'LogManage',
          component: () => import('@/views/admin/system-manage/log-manage')
        }
      ]
    }
  ]
};
