import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ant from '@/utils/ant.js';
import 'ant-design-vue/dist/antd.less';
import '@/assets/less/index.less';
import '@/assets/less/ant.less';

const app = createApp(App);
app
  .use(store)
  .use(router)
  .use(ant)
  .mount('#app');
