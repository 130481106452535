import axios from './http_interceptor';

// 登录
const pcUserLogin = params => {
  return axios.post('/api/login/pcUserLogin', params);
};
// 获取具有权限的菜单列表
const getMenuList = params => axios.get('/api/menu/getPageByUser', { params });
// 单点登陆
const getToken = params => axios.get(`/api/login/loginEpass/?code=${params}`);
// 站内信
const getMail = params => axios.get(`/api/login/loginEpassMsg/?messageGuid=${params}`);
// 退出
const loginOut = params => axios.get(`/api/login/loginOut?userId=${params}`);

export default {
  pcUserLogin,
  getMenuList,
  getToken,
  getMail,
  loginOut
};
