import axios from '../http_interceptor';

/**
 * 监控列表
 */
// 获取监控列表
const getMonitorList = params => axios.post('/api/monitor/tree', params);

// 新增监控
const addMonitor = params => axios.post('/api/monitor', params);

// 新增监控树结构
const addMonitorTree = () => axios.get('/api/monitor/getMonitorResource');

// 编辑监控
const updateMonitor = params => axios.put('/api/monitor', params);

// 删除监控
const delMonitorById = params => axios.delete(`/api/monitor/${params}`);

// 切换监控状态
const toggleMonitorStatus = params => axios.get('/api/monitor/status', { params });


// 监控树
const monitoringTree = params => axios.post('/api/monitor/tree',{ params });

/**
 * 无人机任务列表
 */
// 查询无人机列表
const getUavTaskList = params => axios.post('/api/uavTask/page', params);

// 删除任务
const delTaskById = params => axios.delete(`/api/uavTask/${params}`);

// 获取无人机名称列表
const getUavOptions = () => axios.get('/api/uav/uav-name');

// 新增无人机任务
const addUavTask = params => axios.post('/api/uavTask', params);

// 编辑无人机任务
const updateUavTask = params => axios.put('/api/uavTask', params);

/**
 * 无人机管理
 */
// 获取无人机管理列表
const getUavManageList = params => axios.post('/api/uav/page', params);

// 新增无人机
const addUav = params => axios.post('/api/uav', params);

// 编辑无人机
const updateUav = params => axios.put('/api/uav', params);

// 删除无人机
const delUavById = params => axios.delete(`/api/uav/${params}`);

// 阈值
const getThreshold = () => axios.get(`/api/live/thresholdChangeInfo`);

const setThreshold = params => axios.get(`/api/live/thresholdChange`, { params });

export default {
  getUavTaskList,
  delTaskById,
  getUavOptions,
  addUavTask,
  updateUavTask,
  getUavManageList,
  addUav,
  updateUav,
  delUavById,
  getMonitorList,
  addMonitor,
  updateMonitor,
  delMonitorById,
  toggleMonitorStatus,
  monitoringTree,
  addMonitorTree,
  getThreshold,
  setThreshold
};
