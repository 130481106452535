import axios from '../http_interceptor';

// 新增字典详情
const addDictionaryDetail = params => axios.post('/api/dictionaryDetail', params);

// 更新字典详情
const updateDictionaryDetail = params => axios.put('/api/dictionaryDetail', params);

// 分页查询字典详情
const getDictionaryDetail = params => axios.post('/api/dictionaryDetail/page', params);

// 根据ID查询字典详情
const getDictionaryDetailById = params => axios.get(`/api/dictionaryDetail/${params}`);

// 根据ID删除字典详情
const delDictionaryDetailById = params => axios.delete(`/api/dictionaryDetail/${params}`);

// 新增字典类别
const addDictionaryType = params => axios.post('/api/dictionaryType', params);

// 更新字典类别
const updateDictionaryType = params => axios.put('/api/dictionaryType', params);

// 分页查询字典类别
const getDictionaryType = params => axios.post('/api/dictionaryType/page', params);

// 根据ID查询字典类别
const getDictionaryTypeById = params => axios.get(`/api/dictionaryType/${params}`);

// 根据ID删除字典类别
const delDictionaryTypeById = params => axios.delete(`/api/dictionaryType/${params}`);

export default {
  addDictionaryDetail,
  updateDictionaryDetail,
  getDictionaryDetail,
  getDictionaryDetailById,
  delDictionaryDetailById,
  addDictionaryType,
  updateDictionaryType,
  getDictionaryType,
  getDictionaryTypeById,
  delDictionaryTypeById
};
