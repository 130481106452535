import axios from '../http_interceptor';

// 获取首页 chart 数据
const getHomeChartData = params => axios.post('/api/home-chart-data', params);

// 获取当前直播列表 table data
const getCurrentLiveList = params => axios.get('/api/current-live-list', { params });

// 强制关闭直播间
const forceCloseLiveRoom = params => axios.get(`/api/room/forcedClose/${params.id}`);

// 导出
const exportData = params => axios.get(`/api/home-chart-data/export?dataType=${params.dataType}&dateType=${params.dateType}&endTime=${params.endTime}&startTime=${params.startTime}`);
export default {
  getHomeChartData,
  getCurrentLiveList,
  forceCloseLiveRoom,
  exportData
};
