import { createRouter, createWebHistory } from 'vue-router';
import Cookies from 'js-cookie';
import personalCenter from './personal-center';
import admin from './admin';

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/views/layout'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/home')
      },
      {
        path: 'live-broadcast-watch',
        name: 'liveBroadcastWatch',
        component: () => import('@/views/live-broadcast-watch'),
        
      },
      
      {
        path: 'configuration',
        name: 'configuration',
        component: () => import('@/views/configuration')
      },
      // {
      //   path: 'live-broadcast',
      //   name: 'LiveBroadcast',
      //   component: () => import('@/views/live-broadcast')
      // },
      {
        path: 'configuration',
        name: 'configuration',
        component: () => import('@/views/configuration')
      },
      {
        path: 'video-playback',
        name: 'videoPlayback',
        meta: {
          keepAlive: true //设置页面是否需要使用缓存
       },
        component: () => import('@/views/video-playback')
      },
      {
        path: 'video-playback-watch',
        name: 'videoPlaybackWatch',
        component: () => import('@/views/video-playback-watch')
      },
      {
        path: 'video-recording',
        name: 'videoRecording',
        component: () => import('@/views/video-recording')
      },
      {
        path: 'inspection', // 分屏巡检
        name: 'Inspection',
        component: () => import('@/views/inspection')
      },
      personalCenter,
      {
        path: 'in-mail',
        name: 'InMail',
        component: () => import('@/views/InMail.vue')
      }
    ]
  },
  {
    path: '/live-broadcast-enlarge',
    name: 'Enlarge',
    meta: { requiresAuth: true },
    component: () => import('@/views/live-broadcast-enlarge'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: { requiresAuth: false, name: '登录' }
  },
  {
    path: '/epaas',
    name: 'Epaas',
    component: () => import('@/views/epaas'),
    meta: { requiresAuth: false, name: '重定向' }
  },
  admin,
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // 验证是否需要登录
  const isLogin = Cookies.get('SS_token');
  if (to.matched.some(r => r.meta.requiresAuth) && !isLogin) next({ name: 'Login' });
  else next();
});

export default router;
