import axios from '../http_interceptor';

// 引导页管理 list
const getGuidePageList = params => axios.post('/api/guidance/page', params);

// 新增引导页
const addGuidePage = params => axios.post('/api/guidance', params);

// 更新引导页
const updateGuidePage = params => axios.put('/api/guidance', params);

// 删除引导页
const delGuidePageById = params => axios.delete(`/api/guidance/${params}`);

// 保存引导页配置
const saveGuidePageConfig = params => axios.post('/api/guidance/config', params);

// 获取引导页配置
const getGuidePageConfig = () => axios.get('/api/guidance/config');

export default {
  getGuidePageList,
  addGuidePage,
  updateGuidePage,
  saveGuidePageConfig,
  delGuidePageById,
  getGuidePageConfig
};
