<template>
  <a-config-provider :locale="zh_CN">
    <router-view />
  </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { useStore } from 'vuex';
import { onMounted, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import bus from '../src/bus';
import { message } from 'ant-design-vue';
import api from '@/services';
import { setunread, setcirclePoint } from '../src/store/sharedData';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { path } from '@/config';
export default {
  setup() {
    const router = useRouter();
    const store = useStore();

    const isCode = ref(0);
    const getToken = async () => {
      try {
        const { success, data, code } = await api.getToken(location.search.substring(1).split('=')[1] || '');
        isCode.value = code;
        if (success) {
          Cookies.set('SS_token', data.token);
          localStorage.setItem('SS_userinfo', JSON.stringify(data));
          initSocket(data);
          if (isCode.value == 200) {
            getMenuList();
            router.push('/');
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getMail = async () => {
      try {
        const { success, data, code } = await api.getMail(location.search.substring(1).split('=')[1] || '');
        isCode.value = code;
        if (success) {
          Cookies.set('SS_token', data.token);
          localStorage.setItem('SS_userinfo', JSON.stringify(data));
          initSocket(data);
          if (isCode.value == 200) {
            getMenuList();
            router.push('/in-mail');
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    // 初始化 socket
    const initSocket = data => {
      // http://172.16.16.90:9091  -- 线上
      // http://10.0.10.172:9091   -- 严彬本地
      store.commit(
        'CHANGE_REGISTER_SOCKET',
        io(path, {
          query: `method=register&userId=${data.id}&userName=${data.userName}&equipment=pc`
        })
      );
    };

    // 获取有权限的 菜单列表 / 功能列表
    const getMenuList = async () => {
      let userId = JSON.parse(localStorage.getItem('SS_userinfo'))?.id;
      let { data, success } = await api.getMenuList({ userId });
      if (success) {
        localStorage.setItem('authMenuList', JSON.stringify(data.menuList));
        localStorage.setItem('fnCodeList', JSON.stringify(data.functionList));
        bus.emit('addRoutes');
      }
    };

    const registerSocket = computed(() => store.state.registerSocket);

    const messageSharePage = async () => {
      try {
        let { data, success } = await api.messageSharePage({
          curPage: 1,
          pageSize: 10
        });
        if (success) {
          if(!data) {
            return false
          }
          let dataV = data?.records;
          setcirclePoint(false)
          setunread(dataV[0]?.noReadedCount);
          for (let index = 0; index < dataV.length; index++) {
            if (dataV[index].readed == 0) {
              message.info(`您有一条最新的未读消息，请前往站内信查看`);
              break;
            }
          }
        }
      } catch (error) {
        console.log(error)
        setcirclePoint(true)
        console.error('我的视频列表接口错误！');
      }
    };
    onMounted(() => {
      if (location.search.substring(1).split('=')[0] == 'code' && location.search.substring(1).split('=')[1]) {
        getToken();
      }

      if (location.search.substring(1).split('=')[0] == 'messageGuid' && location.search.substring(1).split('=')[1]) {
        getMail();
      }

      if (location.search.substring(1).split('=')[0] == 'timestamp') {
        location.href = `http://10.45.0.116:8080/epoint-sso-web/rest/oauth2/authorize?client_id=d792ed0a-2bfd-4d39-ab7f-1d066a504f7a&response_type=code
&redirect_uri=http://2.45.177.47:8090`;
      }

      addRoutes();
      setInterval(() => {
        if (window.location.pathname != '/login' && window.location.pathname != '/epaas') messageSharePage();
      }, 60000);
    });

    bus.on('addRoutes', () => {
      addRoutes();
      socketEvent();
    });

    const socketEvent = () => {
      registerSocket.value.on('connect', data => {
        console.log('register socket 连接成功=====', data);
      });

      // 监听后端传过来的数据
      registerSocket.value.on('uavLiveMsg', data => {
        console.log('接收到了register socket数据====', data);
        message.info('您审批通过的无人机已经发起直播了，请前往查看（可以通过站内信前往直播间）');
      });
    };

    // 动态添加路由
    const addRoutes = () => {
      let authMenuList = JSON.parse(localStorage.getItem('authMenuList'));
      if (authMenuList && authMenuList.length) {
        for (let i = 0; i < authMenuList.length; i++) {
          router.addRoute(authMenuList[i].component === 'admin/home' ? 'Admin' : 'Layout', {
            path: authMenuList[i].path,
            name: authMenuList[i].menuName,
            component: () => import(`@/views/${authMenuList[i].component}`)
          });
        }
      }
    };

    return {
      zh_CN
    };
  }
};
</script>

<style lang="less">
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.canvas {
  border: 1px solid #d3d3d3;
  z-index: 9999999;
}
</style>
