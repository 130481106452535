// WebSocket
// export const path = 'wss://kongdi.jiketravel.com';
export const path = 'http://36.154.125.50:9091';
// export const path = 'http://172.16.16.90:9091';
// export const path = 'wss://2.45.177.47:8089';

// export const path = 'http://10.0.10.105:9091';
// export const path = 'http://192.168.1.224:9091'; // 李心雨

// 地图
// export const gis = 'http://221.224.13.41:8088/GIS_GMapCacheProxy/Tile/hashsz/suzhou.gis';

// export const xlsl = 'http://kongdi.jiketravel.com';
export const xlsl = 'https://2.45.177.47:8089';
