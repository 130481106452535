// 分屏巡检
import axios from './http_interceptor';

// 分页查询所有资源
const videoResourcesAllPage = params => {
  return axios.post('/api/videoResources/all/page', params);
};

export default {
  videoResourcesAllPage
};
