import axios from './http_interceptor';

// 发布视频
const releaseVideo = params => {
  axios.defaults.timeout = 2000000;
  axios.defaults.headers.post['Content-Type'] = 'multipart/form-data;charset=utf-8';
  return axios.post('/api/production/splitUpload', params);
};

const check = key => {
  return axios.post(`/api/production/check?key=${key}` );
}

// 获取视频列表
const getVideoList = params => {
  return axios.post('/api/videoResources/page', params);
};

// 新增视频浏览记录
const browseVideo = resourceId => {
  return axios.post('/api/browsingRecord', resourceId);
};

// 根据ID查询资源
const getVideoInformation = id => {
  return axios.get(`/api/videoResources/${id}`);
};

export default {
  getVideoList,
  releaseVideo,
  browseVideo,
  getVideoInformation,
  check
};
