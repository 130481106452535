export default {
  path: '/personal-center',
  name: 'PersonalCenter',
  component: () => import('@/views/personal-center'),
  children: [
    { path: '', redirect: '/personal-center/baseinfo' },
    {
      path: 'baseinfo',
      name: 'PersonalCenterBaseinfo',
      component: () => import('@/views/personal-center/baseinfo')
    },
    {
      path: 'change-password',
      name: 'PersonalCenterChangePassword',
      component: () => import('@/views/personal-center/change-password')
    },
    {
      path: 'user-follow',
      name: 'PersonalCenterUserFollow',
      component: () => import('@/views/personal-center/user-follow')
    },
    {
      path: 'user-video',
      name: 'PersonalCenterUserVideo',
      component: () => import('@/views/personal-center/user-video')
    }
  ]
};
