import axios from '../http_interceptor';

// 查询直播设置
const getLiveSetting = () => axios.get('/api/room/live/config');

// 设置直播设置
const setLiveSetting = params => axios.post('/api/room/live/config', params);

// 首页推荐 / 取消推荐
const toggleRecommend = params => axios.put('/api/room/home-recommend', params);

export default {
  getLiveSetting,
  setLiveSetting,
  toggleRecommend
};
