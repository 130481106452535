import {
  Layout,
  Button,
  Pagination,
  DatePicker,
  Form,
  Input,
  Table,
  TreeSelect,
  Modal,
  message,
  Avatar,
  ConfigProvider,
  Select,
  Upload,
  Tabs,
  Tree,
  Menu,
  Empty,
  Icon,
  Radio,
  Divider,
  Checkbox,
  InputNumber,
  Switch,
  Popconfirm,
  Carousel,
  Dropdown,
  Image,
  Spin,
  Progress,
  Drawer,
} from 'ant-design-vue';
message.config({
  top: `100px`,
  duration: 2,
  maxCount: 1
});
const ant = {
  install(Vue) {
    Vue.use(Layout);
    Vue.use(Button);
    Vue.use(Pagination);
    Vue.use(DatePicker);
    Vue.use(Form);
    Vue.use(Input);
    Vue.use(Table);
    Vue.use(Modal);
    Vue.use(Avatar);
    Vue.use(Select);
    Vue.use(ConfigProvider);
    Vue.use(TreeSelect);
    Vue.use(Upload);
    Vue.use(Tabs);
    Vue.use(Tree);
    Vue.use(Menu);
    Vue.use(Empty);
    Vue.use(Icon);
    Vue.use(Radio);
    Vue.use(Divider);
    Vue.use(Checkbox);
    Vue.use(InputNumber);
    Vue.use(Switch);
    Vue.use(Popconfirm);
    Vue.use(Carousel);
    Vue.use(Dropdown);
    Vue.use(Image);
    Vue.use(Spin);
    Vue.use(Progress);
    Vue.use(Drawer);
    Vue.config.globalProperties.$message = message;
  }
};

export default ant;
