import axios from './http_interceptor';

// 分页查询站内推送
const messageSharePage = params => {
  return axios.post('/api/messageShare/page', params);
};

const updateCode = params => {
  return axios.get(`/api/messageShare/updateCode?id=${params.id}&userId=${params.userId}`);
};

const readShare = () => {
  return axios.get('/api/messageShare/readAllMessageShare');
};

const delectReadShare = () => {
  return axios.get('/api/messageShare/deleteAllReadedMessageShare');
};

export default {
  messageSharePage,
  updateCode,
  readShare,
  delectReadShare
};
