import axios from '../http_interceptor';

// 获取视频管理列表 table data
const getVideoManageList = params => axios.post('/api/production/page', params);

// 视频下架
const offLineVideo = params => axios.post(`/api/production/inOrUp/${params.id}?status=${params.status}`);

// 删除视频
const delVideo = params => axios.delete(`/api/production/${params}`);

// 查询视频设置
const getVideoSetting = () => axios.get('/api/production/config');

// 设置视频设置
const setVideoSetting = params => axios.post('/api/production/config', params);

// 批量删除视频
const allDeleteVideo = params => axios.post('/api/production/deleteProductionByIdList', params);

export default {
  getVideoManageList,
  offLineVideo,
  delVideo,
  getVideoSetting,
  setVideoSetting,
  allDeleteVideo
};
