import axios from './http_interceptor';

// 获取标签(直播间)
const getLiveLabelList = () => {
  return axios.get('/api/label/live');
};

// 获取标签(视频)
const getVideoLabelList = () => {
  return axios.get('/api/label/video');
};

// 获取发布者筛选
const publisherFiltering = (userName = '') => {
  return axios.get(`/api/department/tree?userName=${userName}`);
};

// 获取直播使用资源选择
const getResourceSelection = (query = '') => {
  return axios.get(`/api/resource/getAllResource?query=${query}`);
};

// 幻灯片列表
const getSlide = () => {
  return axios.get('/api/lantern/list?type=1');
};

export default {
  getLiveLabelList,
  getVideoLabelList,
  publisherFiltering,
  getResourceSelection,
  getSlide
};
