import axios from '../http_interceptor';

// 标签管理 list
const getPPTList = params => axios.post('/api/lantern/page', params);

// 删除标签
const delPPTById = params => axios.delete(`/api/lantern/${params}`);

// 新增标签
const addPPT = params => axios.post('/api/lantern', params);

// 编辑标签
const updatePPT = params => axios.put('/api/lantern', params);

export default {
  getPPTList,
  delPPTById,
  addPPT,
  updatePPT
};
