import loginApi from './login.service';
import homeApi from './home.service';
import personalCenterApi from './personal-center.service';
import inMailApi from './in-mail.services';
import inspectionApi from './inspection.service';
import componentsApi from './components.service';
import liveApi from './live.service.js';
import videoApi from './video.service.js';
import adminHomeApi from './admin/home.service';
import adminUserApi from './admin/user.service';
import transcodingApi from './admin/transcoding.service';
import liveRoomApi from './admin/live-room.service';
import warningApi from './admin/warning.service';
import machineApi from './admin/machine.service';
import guideApi from './admin/guide.service';
import tagApi from './admin/tag.service';
import powerpointApi from './admin/ppt.service';
import paramsConfigApi from './admin/params-config.service';
import dictionaryApi from './admin/dictionary.service';

export default {
  ...loginApi,
  ...homeApi,
  ...personalCenterApi,
  ...inMailApi,
  ...inspectionApi,
  ...componentsApi,
  ...liveApi,
  ...videoApi,
  ...adminHomeApi,
  ...adminUserApi,
  ...transcodingApi,
  ...liveRoomApi,
  ...warningApi,
  ...machineApi,
  ...guideApi,
  ...tagApi,
  ...powerpointApi,
  ...paramsConfigApi,
  ...dictionaryApi
};
