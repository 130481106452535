import { reactive, readonly } from 'vue';
// 创建默认的全局单例响应式数据，仅供该模块内部使用
const state = reactive({
  // 点击视频播放显示
  videoPlayShow: false,
  unread: 0,
  // 显示网络是否断开
  circlePoint: false
});

export const setIsType = isType => {
  localStorage.setItem('isType', isType);
};

export const addPlay = () => {
  state.videoPlayShow = !state.videoPlayShow;
};

export const setunread = unread => {
  state.unread = unread;
};

export const setcirclePoint = circlePoint => {
  state.circlePoint = circlePoint;
};

export const sharedDataStore = readonly(state);
