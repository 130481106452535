import axios from 'axios';
import { message } from 'ant-design-vue';
import Cookies from 'js-cookie';

const http = axios.create({
  baseURL: process.env.BASE_URL,
  timeout: 20000, // 请求超时时间20s
  // headers: {'X-Custom-Header': ''}
  validateStatus(status) {
    switch (status) {
      case 400:
        message.error('请求出错');
        break;
      case 401:
        message.warning('授权失败，请重新登录');
        window.location.href = '/login';
        break;
      case 403:
        message.warning({ message: '拒绝访问' });
        break;
      case 404:
        message.error({ message: '请求错误,未找到该资源' });
        break;
      case 500:
        message.error({ message: '服务器连接失败' });
        break;
    }
    return status >= 200 && status < 300;
  }
});

// req拦截
// var loadingInstance;
http.interceptors.request.use(
  config => {
    // loadingInstance = Loading.service({
    //   background: 'rgba(15, 45, 69, 0.4)'
    // });
    if (/\/api/.test(config.url)) {
      config.headers = {
        'Content-Type': 'application/json;chartset=utf-8',
        ...config.headers
      };
    }
    // 删除入参空值
    if (config.data) {
      Object.keys(config.data).forEach(key => {
        let val = config.data[key];
        if (val === '' || val === null || val === []) {
          delete config.data[key];
        }
      });
    }

    // 请求头添加token
    if (Cookies.get('SS_token')) {
      config.headers.token = Cookies.get('SS_token');
    }
    return config;
  },
  error => {
    // loadingInstance.close();
    // console.error('请求错误=====>[params]:', error);
    return Promise.reject(error);
  }
);

// res拦截
http.interceptors.response.use(
  response => {
    const res = response.data;
    if (!res.success) {
      if (res instanceof Blob) {
        return false;
      }
      if ([401, 403].includes(res.code)) {
        localStorage.clear();
        Cookies.remove('SS_token');
        location.href = '/login';
      }
      message.error(res.msg);
      return res;
    }
    // loadingInstance.close();
    return res;
  },
  error => {
    // loadingInstance.close();
    // Message.error(error.message);
    return Promise.reject(error);
  }
);

export default http;
