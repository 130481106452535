import axios from './http_interceptor';

// 我的关注
const getMyAttentionList = params => {
  return axios.post('/api/room/followList', params);
};

// 最近观看
const getRecentlyWatchedList = params => {
  return axios.post('/api/browsingRecord/page', params);
};

// 消息推送
const getMessagePush = () => {
  return axios.get('/api/notice/latest');
};

// 右侧推荐位
const getRecommender = params => {
  return axios.post('/api/room/recommendLiveRoom', params);
};

export default {
  getMyAttentionList,
  getRecentlyWatchedList,
  getMessagePush,
  getRecommender
};
