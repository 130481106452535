import axios from '../http_interceptor';

// 标签管理 list
const getTagList = params => axios.post('/api/label/page', params);

// 新增标签
const addTag = params => axios.post('/api/label', params);

// 更新标签
const updateTag = params => axios.put('/api/label', params);

// 删除标签
const delTagById = params => axios.delete(`/api/label/${params}`);

// 保存标签配置
const saveTagConfig = params => axios.post('/api/label/config', params);

// 获取标签配置
const getTagConfig = () => axios.get('/api/label/config');

export default {
  getTagList,
  delTagById,
  addTag,
  updateTag,
  saveTagConfig,
  getTagConfig
};
