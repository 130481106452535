import axios from './http_interceptor';

// 个人中心
const userPersonalCenter = () => {
  return axios.get('/api/user/personalCenter');
};

// 修改密码
const userChangePassword = params => {
  return axios.put('/api/user/changePassword', params);
};

// 查询我的关注列表
const userFollowList = params => {
  return axios.get('/api/user/followList', { params: params });
};

// 查询我的视频
const userProductList = params => {
  return axios.post('/api/user/productList', params);
};

// 删除我的视频
const userDeleteVideo = id => {
  return axios.delete(`/api/videoResources/${id}`);
};

export default {
  userPersonalCenter,
  userChangePassword,
  userFollowList,
  userProductList,
  userDeleteVideo
};
