import { createStore } from 'vuex';

export default createStore({
  state: {
    registerSocket: null // 用于登录时在后台注册时的socket对象
  },
  mutations: {
    CHANGE_REGISTER_SOCKET(state, val) {
      state.registerSocket = val;
    }
  },
  actions: {},
  modules: {}
});
