import axios from '../http_interceptor';

/**
 * 用户列表页
 */

// 条件查询用户列表 table data
const getUserList = params => axios.post('/api/user/localUserListPage', params);

// 根据用户删除用户
const delUserById = params => axios.delete(`/api/user/${params}`);

// 批量绑定用户角色
const batchBindRole = params => axios.post('/api/userRole', params);

// 批量设置角色列表
const getSetRoleList = () => axios.post('/api/role/getAllRole');

// 获取用户组织下拉选项
const getOrganizationOptions = () => axios.get('/api/department/getDepartmentTreeList');

// 获取用户角色下拉选项
const getRoleOptions = () => axios.post('/api/role/list');

//  更新用户
const updateUser = params => axios.put('/api/user/updateLocalUser', params);

//  新增用户
const addUser = params => axios.post('/api/user/createLocalUser', params);

//  冻结/解冻 用户
const toggleFreezeUser = params => axios.post('/api/user/localUserChangeStatus', params);

// 根据用户 id 查询用户信息
const getUserInfoById = params => axios.get(`/api/user/findLocalUserById/${params}`);

/**
 * 角色管理页
 */

// 条件查询获取角色列表 table data
const getRoleList = params => axios.post('/api/role/page', params);

// 删除角色
const delRoleById = params => axios.delete(`/api/role/${params}`);

// 新增角色
const addRole = params => axios.post('/api/role', params);

// 根据角色 id 查询权限
const getRoleAuthById = params => axios.get('/api/role/role-permission', { params });

// 修改角色权限
const updateAuthById = params => axios.put('/api/role', params);

// 根据角色 id 查询资源信息 树状结构
const getRoleResourceTreeDataById = params => axios.get('/api/resource/tree', { params });

/**
 * 资源管理
 */
// 条件查询资源管理列表 table data
const getResourceList = params => axios.post('/api/videoResources/manage', params);

// 删除资源
const delResourceById = params => axios.delete('/api/videoResources/manage', { params });

// 编辑资源
const updateResource = params => axios.put('/api/videoResources/manage', params);

// 同步海康
const synchronous = () => axios.get('/api/hikvision/flushResource');
export default {
  getUserList,
  delUserById,
  batchBindRole,
  getOrganizationOptions,
  getRoleOptions,
  updateUser,
  addUser,
  getUserInfoById,
  getRoleList,
  delRoleById,
  addRole,
  updateAuthById,
  getRoleAuthById,
  getRoleResourceTreeDataById,
  getResourceList,
  delResourceById,
  updateResource,
  toggleFreezeUser,
  getSetRoleList,
  synchronous
};
