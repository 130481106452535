import axios from './http_interceptor';

// 发起直播
const sendLiveBroadcast = params => {
  return axios.post('/api/live/liveLaunch', params);
};

// 获取直播列表
const getLiveList = params => {
  return axios.post('/api/room/page', params);
};

// 直播心跳
const liveHeartbeat = () => {
  return axios.post('api/live/anchor_heartbeat');
};

// 查询直播间
// 房间ID
const queryLiveRoom = id => {
  return axios.get(`api/room/${id}`);
};

// 历史回放列表
// 房间ID
const getHistoryList = id => {
  return axios.post(`/api/history/playback/${id}`);
};

// 关注
const follow = userId => {
  return axios.get(`/api/followRelation/follow/${userId}`);
};

// 取消关注
const cancelFollow = userId => {
  return axios.delete(`/api/followRelation/follow/${userId}`);
};

// 强制关闭
const closeLive = roomId => {
  return axios.get(`/api/room/forcedClose/${roomId}`);
};

// 链接分享(直播)
const linkSharing = roomId => {
  return axios.get(`api/share/getShareHtml?roomId=${roomId}`);
};

// 踢出
// roomId and userName
const kickOut = params => {
  return axios.post(`api/room/kick`, params);
};

// 设置密码
const setPassword = params => {
  return axios.post(`api/room/setPassword`, params);
};

// 站内推送
const liveShare = params => {
  return axios.post(`api/messageShare`, params);
};

// 切换视频资源
// resourceType;
// videoResourceId;
const switchVideo = params => {
  return axios.post(`api/live/switchResource`, params);
};

// 根据ID查询资源
const watchVideo = (cameraIndexCode = '') => {
  return axios.get(`api/hikvision/previewURLs?cameraIndexCode=${cameraIndexCode}`);
};

// 违法类型获取
const getTypesViolation = () => {
  return axios.get(`api/alarm/illegal-type`);
};

// 预警提交
const earlyWarning = params => {
  return axios.post(`api/alarm/add_alarm`, params);
};

// 密码验证
const passwordCheck = params => {
  return axios.get(`api/room/checkPassword`, { params });
};

// 直播发起封面默认
const getLiveCover = () => {
  return axios.get(`api/room/live/config`);
};

// 主播进入直播间后默认的图片
const getLiveRoomCover = () => {
  return axios.get(`api/guidance/getOne`);
};

// 耻辱下播
const liveDown = () => {
  return axios.post(`api/live/liveDown`);
};

// 地图点位
const mapPoints = () => {
  return axios.post(`api/monitor/getAllNearbyCamera`);
}

// 地图单乒
const mapSingleTennis = () => {
  axios.defaults.timeout = 4000000;
  return axios.get(`api/room/getPdtInfo`);
}

// 连麦
const evenwheat = (roomId) => {
  return axios.get(`api/live/liveCall?roomId=${roomId}`);
}

// 接受或拒绝
// attitude
// userName  msgContent
const yesOnNo = (params) => {
  return axios.get(`api/live/liveCallResponse`, { params });
}

// 关闭后连麦后的请求
// roomid
const endCall = (params) => {
  return axios.get(`api/live/endCall?roomId=${params}`);
}

const getMapLive = (id) => {
  return axios.get(`api/monitor/${id}`);
}

const getChangeAlgorithmStatus = (value) => {
  return axios.get(`api/room/changeAlgorithmStatus/${value}`);
}

const getNearbyCameraByIds = (params) => {
  return axios.post(`api/monitor/getNearbyCameraByIds`,params);
}

// 代办
const agent = (params) => {
  return axios.post(`api/alarm/add_todo`,params);
}
export default {
  sendLiveBroadcast,
  getLiveList,
  liveHeartbeat,
  queryLiveRoom,
  getHistoryList,
  follow,
  cancelFollow,
  closeLive,
  linkSharing,
  kickOut,
  setPassword,
  liveShare,
  switchVideo,
  watchVideo,
  getTypesViolation,
  earlyWarning,
  passwordCheck,
  getLiveCover,
  getLiveRoomCover,
  liveDown,
  mapPoints,
  mapSingleTennis,
  evenwheat,
  yesOnNo,
  endCall,
  getMapLive,
  getChangeAlgorithmStatus,
  getNearbyCameraByIds,
  agent
};
