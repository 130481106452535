import axios from '../http_interceptor';

const getWarningList = params => axios.post('/api/alarm/list', params);

const exportWarning = params => {
  return axios({
    // 用axios发送post请求
    method: 'post',
    url: 'api/alarm/export', // 请求地址
    data: params, // 参数
    responseType: 'blob', // 表明返回服务器返回的数据类型
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const setWarningList = params => axios.put('/api/alarm', params);


export default {
  getWarningList,
  exportWarning,
  setWarningList
};
